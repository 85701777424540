import {
    ReduxAction,
    SURVEY_FETCH_SUCCESS,
    SURVEY_FETCH_ERROR,
    SURVEY_ANSWER_ERROR,
    SURVEY_ANSWER_LOADING,
    SURVEY_ANSWER_SUCCESS,
    SURVEY_FETCH_LOADING,
    SURVEY_DOMAIN_CHANGE,
    SURVEY_SUBDOMAIN_CHANGE,
    SURVEY_FIRST_TIME_LOADING,
    APPLICATION_SETTING_FETCH_SUCCESS,
    APPLICATION_SETTING_FETCH_ERROR,
} from '../actions/surveyTypes';
import { SurveyState } from '../redux-state';

const surveyState = (
    state: SurveyState = {
        loading: true,
        answerLoading: false,
        error: undefined,
        surveyClosed: undefined,
        staticDomainAverageColor: undefined,
        feedbackFeatureEnabled: undefined,
        logoSrc: 'talentbuilderlogo.png',
        domainDefaultProficiency: undefined,
        retakeFeatureEnabled: undefined,
        multiLingualEnabled: undefined,
        summaryFeatureEnabled: undefined,
        domains: [],
        domainCursor: 0,
        presurveyCompleted: false,
        userInfos: {
            lastAnsweredTime: 0,
            gdprAccepted: null,
            surveyCompleted: null,
            moreInfo: {},
        },
        firstTimeLoading: true,
    },
    action: ReduxAction,
): SurveyState => {
    switch (action.type) {
        case SURVEY_FETCH_SUCCESS:
        case SURVEY_FETCH_ERROR:
        case SURVEY_FETCH_LOADING:
        case SURVEY_ANSWER_ERROR:
        case SURVEY_ANSWER_LOADING:
        case SURVEY_ANSWER_SUCCESS:
        case SURVEY_DOMAIN_CHANGE:
        case SURVEY_SUBDOMAIN_CHANGE:
        case SURVEY_FIRST_TIME_LOADING:
        case APPLICATION_SETTING_FETCH_SUCCESS:
        case APPLICATION_SETTING_FETCH_ERROR:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default surveyState;
