import { breakpoints, colors, fonts } from '../styles';
import useViewport from '../utils/useViewport';
import AnswerButton from './AnswerButton';
import Button from './Button';
import PopinPageLayout from './PopinPageLayout';
import { LocationDescriptorObject } from 'history';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    NavLink,
    useParams,
    Link,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import styled from 'styled-components';

interface HelpPopinProps {
    onClose: () => void;
    permanentPage?: boolean;
}

const ExplanationIntro = styled.div`
    ${fonts.content};
    color: ${colors.textColor1};
    margin-bottom: 60px;
`;

const AnswersExplanation = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 300px;
    ${breakpoints.onlyMobile} {
        height: auto;
    }
`;

const Row = styled.div`
    display: flex;
    ${breakpoints.onlyMobile} {
        flex-direction: column;
        background-color: rgba(24, 24, 24, 0.2);
        border-radius: 16px;
        padding: 40px 20px;
        margin: 1em 0;
    }
`;

const AnswerExplanation = styled.div`
    ${fonts.indication};
    color: ${colors.textColor1};
    ${breakpoints.onlyMobile} {
        margin: 1em 1em 0em 1em;
        text-align: center;
    }
    ${breakpoints.sm} {
        align-items: center;
        display: flex;
        font-size: 16px;
    }
    ${breakpoints.lg} {
        align-items: center;
        display: flex;
        font-size: 16px;
    }
    ${breakpoints.md} {
        align-items: center;
        display: flex;
        font-size: 16px;
    }
    ${breakpoints.largerDesktop} {
        font-size: 16px;
        align-items: center;
        display: flex;
    }
    ${breakpoints.largeDesktop} {
        font-size: 16px;
        align-items: center;
        display: flex;
    }
`;

const SizedAnswerButton = styled(AnswerButton)`
    justify-content: space-between;
    width: 250px;
    margin-right: 20px;
    height: 50px;
    ${breakpoints.onlyMobile} {
        justify-content: center;
        margin: 0 auto;
    }
`;

const NextStepContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    text-decoration: none;
    margin-bottom: 20px;
    margin-top: 40px;
`;

const Content = styled.div`
    ${fonts.content}
    margin-top: 60px;
    color: ${colors.textColor1};
`;

const FootNote = styled.div`
    ${breakpoints.onlyMobile} {
        font-size: 12px;
    }
    font-size: 16px;
    color: ${colors.textColor1};
`;
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const HelpPopin: React.FC<HelpPopinProps> = ({
    onClose,
    permanentPage = false,
}) => {
    const { width } = useViewport();
    const { t } = useTranslation(['HelpPopin']);
    const { survey_id, uid } = useParams();

    const [levelsConfig, setLevelsConfig] = useState<any>(null);

    useEffect(() => {
        async function fetchConfig() {
            const response = await fetch(`${backendUrl}/get_levels`,{
                headers: {
                    'Survey-Id': survey_id || ''
                }
            });
            const data = await response.json();
            setLevelsConfig(data);
        }
        fetchConfig();
    }, []);

    let emailAuth;
    const navigate = useNavigate();
    const location = useLocation();
    const stateVar = location.state;
    if (!stateVar || !stateVar.emailAuth) {
        navigate(`/${survey_id}/${uid}`, { replace: true });
    } else {
        emailAuth = stateVar.emailAuth;
    }

    return (
        <PopinPageLayout
            onClose={onClose}
            title={t('title')}
            subtitle={t('subtitle')}
            permanentPage={permanentPage}
        >
            <ExplanationIntro
                dangerouslySetInnerHTML={{
                    __html:
                        width > breakpoints.value.mobile
                            ? t('explanation')
                            : t('explanationMobile'),
                }}
            />
            <AnswersExplanation>
                {levelsConfig &&
                    levelsConfig.levels_data.map((level: any) => (
                        <Row key={level.level}>
                            <SizedAnswerButton
                                index={level.id}
                                answerLevel={level.answerLevel}
                                backgroundType="content"
                                shortcut={level.shortcut}
                                tooltipTextColor={colors.level1}
                                color={level.color}
                                level={level.level}
                                levels_state={levelsConfig}
                                cursor={false}
                            />
                            <AnswerExplanation>
                                {t(level.answerLevelExplanation)}
                            </AnswerExplanation>
                        </Row>
                    ))}
                {/* <Row>
                    <SizedAnswerButton
                        backgroundType="content"
                        shortcut={'1'}
                        tooltipTextColor={colors.level1}
                        color={colors.level1}
                        level={1}
                        cursor={false}
                    />
                    <AnswerExplanation>
                        {t('answerLevel1Explanation')}
                    </AnswerExplanation>
                </Row> */}
            </AnswersExplanation>

            <Content>{t('underNote')}</Content>
            {!permanentPage && <br />}

            {permanentPage && (
                <NextStepContainer>
                    <NavLink
                        to={`/${survey_id}/${uid}/${t('previousViewSlug')}`}
                        state={{ emailAuth: emailAuth }}
                    >
                        <Button
                            buttonType={`secondary`}
                            textColor="black"
                            borderColor="black"
                        >
                            {t('previousButtonText')}
                        </Button>
                    </NavLink>
                    <NavLink
                        to={{
                            pathname: `/${survey_id}/${uid}/${t(
                                'nextViewSlug',
                            )}`,
                        }}
                        state={{
                            levels_state: levelsConfig,
                            emailAuth: emailAuth,
                        }}
                    >
                        <Button
                            buttonType={`secondary`}
                            textColor="black"
                            borderColor="black"
                        >
                            {t('nextButtonText')}
                        </Button>
                    </NavLink>
                </NextStepContainer>
            )}

            {/* <FootNote>{t('footNote')}</FootNote> */}
        </PopinPageLayout>
    );
};
export default HelpPopin;
