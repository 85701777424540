// import QuestionProgressDisplay from '../components/QuestionProgressDisplay';
// import CircleIcon from '../components/CircleIcon';
import { colors, containerStyles, fonts } from '../styles';
import React from 'react';
import styled from 'styled-components';
import { globalColor } from '../utils/globals';

// import ProgressControls from '../components/ProgressControls';
// import AnswerButton from '../components/AnswerButton';
// import Checkbox from '../components/Checkbox';
// import AnswerButton from '../components/AnswerButton';
// import Answers from '../components/Answers';

const MissingPageWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    background-color: ${globalColor.backgroundColor2};
    ${fonts.content};
    color: ${colors.white};
    display: flex;
    z-index: 9999;
    align-items: center;
`;
const MissingPageContent = styled.div``;

const MissingPage: React.FC = (): React.ReactElement => {
    // const [checked, setChecked] = useState(false);
    return (
        <MissingPageWrapper>
            <MissingPageContent
                dangerouslySetInnerHTML={{
                    __html: '404',
                }}
            />
        </MissingPageWrapper>
    );
};

export default MissingPage;
