/* eslint-disable prettier/prettier */
import {
    validateSurvey,
    validateAnswer,
    validateDomain,
    validateSubdomain,
    validateApplicationSettings,
} from '../model/joi.models';
import {
    Survey,
    AnswerLevel,
    Domain,
    Id,
    Subdomain,
    MoreInfoAnswerObject,
    ApplicationSettings
} from '../model/models';
import { State } from '../redux-state';
import { lightenHexColor } from '../utils/colorUtil';
import { setGlobalColors } from '../utils/globals';
import {
    authenticatedGetJson,
    authenticatedPostJson,
    authenticatedPutJson,
    authenticatedGet,
} from '../utils/requests';
import {
    SURVEY_FETCH_SUCCESS,
    SURVEY_FETCH_ERROR,
    SURVEY_FETCH_LOADING,
    SURVEY_ANSWER_SUCCESS,
    SURVEY_ANSWER_ERROR,
    SURVEY_SUBDOMAIN_CHANGE,
    SURVEY_FIRST_TIME_LOADING,
    SurveyFetchError,
    SurveyAnswerLoading,
    SurveyFetchSuccess,
    SurveyFetchLoading,
    SurveyAnswerSuccess,
    SurveyAnswerError,
    SurveyFirstTimeLoading,
    AppliationSettingFetchSuccess,
    AppliationSettingFetchError,
    SURVEY_ANSWER_LOADING,
    APPLICATION_SETTING_FETCH_SUCCESS,
    APPLICATION_SETTING_FETCH_ERROR,
} from './surveyTypes';
import download from 'downloadjs';
import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

const backendUrl = process.env.REACT_APP_BACKEND_URL || '';
const getSurveyEndpoint = process.env.REACT_APP_GET_SURVEY_ENDPOINT || '';
const answerDomainEndpoint: string =
    process.env.REACT_APP_ANSWER_DOMAIN_ENDPOINT || '';
const answerSubdomainEndpoint: string =
    process.env.REACT_APP_ANSWER_SUBDOMAIN_ENDPOINT || '';
const answerGDPREndpoint: string =
    process.env.REACT_APP_ANSWER_GDPR_ENDPOINT || '';

const moreInfoEndpoint: string =
    process.env.REACT_APP_ANSWER_MORE_INFO_ENDPOINT || '';

const downloadSummaryEndpoint: string =
    process.env.REACT_APP_DOWNLOAD_SUMMARY_ENDPOINT || '';

const surveyFetchError = (error: Error): SurveyFetchError => {
    return {
        type: SURVEY_FETCH_ERROR,
        error: true,
        payload: {
            error,
            firstTimeLoading: false,
        },
    };
};

const surveyFetchUnauthorizedError = (error: Error): SurveyFetchError => {
    return {
        type: SURVEY_FETCH_ERROR,
        error: true,
        payload: {
            error,
            surveyClosed: true,
            firstTimeLoading: false,
        },
    };
};

const surveyFetchSuccess = (survey: Survey): SurveyFetchSuccess => {
    return {
        type: SURVEY_FETCH_SUCCESS,
        payload: {
            ...survey,
        },
    };
};

const surveyAnswerLoading = (answerLoading: boolean): SurveyAnswerLoading => {
    return {
        type: SURVEY_ANSWER_LOADING,
        payload: {
            answerLoading: answerLoading,
        },
    };
};

const applicationSettingFetchError = (error: Error): AppliationSettingFetchError => {
    return {
        type: APPLICATION_SETTING_FETCH_ERROR,
        error: true,
        payload: {
            error
        },
    };
};

const applicationSettingFetchSuccess = (applicationSettings: any): AppliationSettingFetchSuccess => {
    return {
        type: APPLICATION_SETTING_FETCH_SUCCESS,
        payload: {
            staticDomainAverageColor : applicationSettings.staticDomainAverageColor,
            feedbackFeatureEnabled: applicationSettings.feedbackFeatureEnabled,
            domainDefaultProficiency: applicationSettings.domainDefaultProficiency,
            retakeFeatureEnabled: applicationSettings.retakeFeatureEnabled,
            multiLingualEnabled: applicationSettings.multiLingualEnabled,
            summaryFeatureEnabled: applicationSettings.summaryFeatureEnabled,
            logoSrc: applicationSettings.logoSrc,
        },
    };
};

const surveyAnswerSuccess = (): SurveyAnswerSuccess => {
    return {
        type: SURVEY_ANSWER_SUCCESS,
        payload: {},
    };
};

const surveyAnswerError = (error: Error): SurveyAnswerError => {
    return {
        type: SURVEY_ANSWER_ERROR,
        error: true,
        payload: {
            error,
        },
    };
};

const setSurveyFetchLoading = (loadingStatus: boolean): SurveyFetchLoading => {
    return {
        type: SURVEY_FETCH_LOADING,
        payload: {
            loading: loadingStatus,
        },
    };
};

const firstTimeLoading = (loadingStatus: boolean): SurveyFirstTimeLoading => {
    return {
        type: SURVEY_FIRST_TIME_LOADING,
        payload: {
            firstTimeLoading: loadingStatus,
        },
    };
};

/**
 * Get the validated application settings
 */
const getApplicationSettings = (
): ThunkAction<void, State, null, Action> => {
    return async (
        dispatch: ThunkDispatch<State, null, Action<any>>,
    ): Promise<any> => {
        let applicationSettings: ApplicationSettings;
        try {
            const resp = await authenticatedGetJson(`${backendUrl}${`/get_application_settings`}`);
            applicationSettings = validateApplicationSettings(resp);
        } catch (err) {
            return dispatch(applicationSettingFetchError(err as Error));
        }
        setGlobalColors({
            backgroundColor1:applicationSettings.leftPanelColor,
            backgroundColor2:applicationSettings.rightPanelColor,
            linkColor:applicationSettings.rightPanelColor,
            titleColor:applicationSettings.rightPanelColor,
            skillColor:applicationSettings.rightPanelColor,
            primaryButton:lightenHexColor(applicationSettings.rightPanelColor, 15),
            primaryButtonHover:applicationSettings.rightPanelColor,
        });
        dispatch(applicationSettingFetchSuccess(applicationSettings));
    };
};

/**
 * Get the VALIDATED survey
 */
const getSurvey = (
    survey_id: any,
    uid: any,
): ThunkAction<void, State, null, Action> => {
    return async (
        dispatch: ThunkDispatch<State, null, Action<any>>,
    ): Promise<any> => {
        let survey: Survey;
        dispatch(setSurveyFetchLoading(true));
        try {
            const resp = await authenticatedGetJson(
                `${backendUrl}${getSurveyEndpoint}`,
                uid
            );
            if (resp == 401) {
                return dispatch(
                    surveyFetchUnauthorizedError(new Error('Unauthorized')),
                );
            }
            survey = validateSurvey(resp);
        } catch (err) {
            return dispatch(surveyFetchError(err as Error));
        }

        if (Object.entries(survey).length === 0) {
            dispatch(setSurveyFetchLoading(false));
            dispatch(firstTimeLoading(false));
            return dispatch(
                surveyFetchError(new Error('No response from server')),
            );
        }
        dispatch(surveyFetchSuccess(survey));
        dispatch(setSurveyFetchLoading(false));
        dispatch(firstTimeLoading(false));
    };
};

/**
 * DEBUG - Reset the progress
 */

const debugResetApp = (
    survey_id: any,
    uid: any,
): ThunkAction<void, State, null, Action> => {
    return async (
        dispatch: ThunkDispatch<State, null, Action<any>>,
    ): Promise<any> => {
        try {
            await authenticatedPostJson(
                `${backendUrl}/reset`,
                {}, uid,
            );
        } catch (err) {
            return dispatch(surveyFetchError(err as Error));
        }
        dispatch(getSurvey(survey_id, uid));
        document.location.href = `/${survey_id}/${uid}`;
    };
};

const goToSubdomain = (subdomainId: Id) => {
    return {
        type: SURVEY_SUBDOMAIN_CHANGE,
        payload: {
            subdomainCursor: subdomainId,
        },
    };
};

/**
 * As a Redux action, answer a domain with an AnswerLevel
 * @param domain
 * @param answerLevel
 */
const answerDomain = (
    domain: Domain,
    answerLevel: AnswerLevel,
    survey_id: any,
    uid: any,
): ThunkAction<void, State, null, Action> => {
    return async (
        dispatch: ThunkDispatch<State, null, Action<any>>,
    ): Promise<any> => {
        let response;

        const answerUrl = `${backendUrl}${answerDomainEndpoint}`;

        // Start loading
        dispatch(setSurveyFetchLoading(true));

        try {
            // Validate Pararms

            domain = validateDomain(domain);
            answerLevel = validateAnswer(answerLevel);

            // Fill url
            const filledAnswerUrl = answerUrl.replace(
                ':id',
                domain.id.toString(),
            );

            const payload = { level: answerLevel };
            dispatch(surveyAnswerLoading(true));
            // Post answer
            response = await authenticatedPostJson(filledAnswerUrl, payload, uid);
        } catch (err) {
            dispatch(surveyAnswerError(err as Error));
            return dispatch(setSurveyFetchLoading(false));
        }

        // Dispatch success, end loading
        dispatch(surveyAnswerLoading(false));
        dispatch(surveyAnswerSuccess());
        dispatch(getSurvey(survey_id, uid));

        return response;
    };
};

/**
 * As a Redux action, answer a subdomain with an AnswerLevel
 * @param subdomain
 * @param answerLevel
 */

const answerSubdomain = (
    subdomain: Subdomain,
    answerLevel: AnswerLevel,
    survey_id: any,
    uid: any,
): ThunkAction<void, State, null, Action> => {
    return async (
        dispatch: ThunkDispatch<State, null, Action<any>>,
    ): Promise<any> => {
        const answerUrl = `${backendUrl}${answerSubdomainEndpoint}`;

        // Start loading
        dispatch(setSurveyFetchLoading(true));
        try {
            // Validate Pararms

            subdomain = validateSubdomain(subdomain);
            answerLevel = validateAnswer(answerLevel);

            // Fill url
            const filledAnswerUrl = answerUrl.replace(
                ':id',
                subdomain.id.toString(),
            );

            const payload = { level: answerLevel };
            dispatch(surveyAnswerLoading(true));
            // Post answer and get next question ID
            await authenticatedPostJson(filledAnswerUrl, payload, uid);
        } catch (err) {
            dispatch(surveyAnswerError(err as Error));
            return dispatch(setSurveyFetchLoading(false));
        }

        // Dispatch success, end loading
        dispatch(surveyAnswerLoading(false));
        dispatch(surveyAnswerSuccess());
        dispatch(getSurvey(survey_id, uid));
    };
};

/**
 * As a Redux action, accept the GDPR conditions
 */

const acceptGDPR = (
    survey_id: any,
    uid: any,
): ThunkAction<void, State, null, Action> => {
    return async (
        dispatch: ThunkDispatch<State, null, Action<any>>,
    ): Promise<any> => {
        const answerUrl = `${backendUrl}${answerGDPREndpoint}`;
        try {
            // Post answer and get next question ID
            await authenticatedPostJson(answerUrl, {}, uid);
        } catch (err) {
            return dispatch(surveyAnswerError(err as Error));
        }
        dispatch(surveyAnswerSuccess());
        dispatch(getSurvey(survey_id, uid));
    };
};

/**
 * As a Redux action, submit the informations from the More info page
 */
const submitMoreInfoAnswers = (
    answers: MoreInfoAnswerObject,
    survey_id: any,
    uid: any,
    callback: (status: boolean) => void
): ThunkAction<void, State, null, Action> => {
    return async (
        dispatch: ThunkDispatch<State, null, Action<any>>,
    ): Promise<any> => {
        const answerUrl = `${backendUrl}${moreInfoEndpoint}`;

        try {
            // Post answer and get next question ID
            const response = await authenticatedPutJson(answerUrl, answers, uid);
            callback(response.ok);
        } catch (err) {
            return dispatch(surveyAnswerError(err as Error));
        }
        dispatch(surveyAnswerSuccess());
        dispatch(getSurvey(survey_id, uid));
    };
};

/**
 * Get my summary PDF from the server
 */

const getSummaryPDF = (
    uid: any,
): ThunkAction<void, State, null, Action> => {
    return async (
        dispatch: ThunkDispatch<State, null, Action<any>>,
    ): Promise<any> => {
        const getPDFUrl = `${backendUrl}${downloadSummaryEndpoint}`;

        let response;
        let blob;
        try {
            response = await authenticatedGet(getPDFUrl, uid);
            blob = await response.blob();
            download(blob, 'mytechnjourneysummary.pdf', 'application/pdf');
        } catch (err) {
            return dispatch(surveyAnswerError(err as Error));
        }
        dispatch(surveyAnswerSuccess());
    };
};

const updateUserCompleted = (
    uid: any,
    callback: (status: boolean) => void
): ThunkAction<void, State, null, Action> => {
    return async (
        dispatch: ThunkDispatch<State, null, Action<any>>,
    ): Promise<any> => {
        
        try {
            let response = await authenticatedGet(`${backendUrl}/user_survey_completed`, uid);
            callback(response.ok);
        } catch (err) {
            return dispatch(surveyAnswerError(err as Error));
        }
        dispatch(surveyAnswerSuccess());
    };
};

export {
    getApplicationSettings,
    getSurvey,
    answerDomain,
    answerSubdomain,
    goToSubdomain,
    firstTimeLoading,
    acceptGDPR,
    submitMoreInfoAnswers,
    debugResetApp,
    getSummaryPDF,
    updateUserCompleted,
};

