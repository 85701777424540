export let globalColor = {
    backgroundColor1: '#F1EEEA',
    backgroundColor2: '#197A56',
    linkColor: '#197A56',
    titleColor: '#197A56',
    skillColor: '#197A56',
    primaryButton: '#197A56',
    primaryButtonHover: '#197A56',
    primaryButtonText: '#FFFFFF',
};

export const setGlobalColors = (newData: any) => {
    globalColor = { ...globalColor, ...newData };
};
