import { colors, fonts } from '../styles';
import { globalColor } from '../utils/globals';
import CircleIcon from './CircleIcon';
import InformationLayout from './InformationLayout';
import React from 'react';
import styled from 'styled-components';

interface PopinPageLayoutProps {
    onClose: () => void;
    title: string;
    subtitle: string;
    permanentPage?: boolean;
    children?: React.ReactNode;
}

const Title = styled.div`
    ${fonts.content};
    color: ${globalColor.titleColor};
    text-transform: uppercase;
    margin-bottom: 32px;
`;

const Subtitle = styled.div`
    ${fonts.subtitle};
    color: ${colors.textColor1};
    margin-bottom: 32px;
`;

const TopRow = styled.div`
    display: flex;
    justify-content: space-between;
`;
const PopinContent = styled.div``;

const PopinPageLayout: React.FC<PopinPageLayoutProps> = ({
    title,
    subtitle,
    children,
    onClose,
    permanentPage = false,
}) => {
    return (
        <InformationLayout
            leftSideBackgroundColor={
                permanentPage
                    ? globalColor.backgroundColor1
                    : globalColor.backgroundColor1
            }
            withButton={permanentPage}
            leftSize={100}
            logoImage="/talentbuilderlogo.png"
        >
            <PopinContent>
                <TopRow>
                    <Title>{''}</Title>
                    {!permanentPage && (
                        <CircleIcon
                            onClick={() => onClose()}
                            type={'close'}
                            borderColor={colors.white}
                        />
                    )}
                </TopRow>
                <Subtitle
                    dangerouslySetInnerHTML={{
                        __html: subtitle,
                    }}
                />
                {children}
            </PopinContent>
        </InformationLayout>
    );
};
export default PopinPageLayout;
