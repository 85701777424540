export default {
    white: '#fff',
    lightWhite: 'rgba(255, 255, 255, 0.75)',
    white20: 'rgba(255, 255, 255, 0.2)',
    white75: 'rgba(255, 255, 255, 0.75)',
    white90: 'rgba(255, 255, 255, 0.9)',
    black: '#000000',
    lightGray: '#999999',
    lightGray10: 'rgba(153, 153, 153, 0.1)',
    gray: '#333333',
    greyShuttle: '#596275',
    red: '#fc5c65',
    redHover: '#ff0000',
    blue30: 'rgba(153, 174, 206, 0.3)',
    blue: '#99aece',
    blueLight: '#3dc1d3',
    blueBrilliant: '#546de5',
    blueDark: '#3E4178',
    blueText: '#02b3d6',
    pink: '#f8a5c2',
    gradientBlueDark: 'linear-gradient(145deg, #13152a, #4e53a4)',
    gradientBlack: 'linear-gradient(143deg, #000000, #464646)',
    gradiantBlackToWhiteGradiant: 'linear-gradient(90deg, #999999, #ffffff)',
    primary100: '#E0E7F0',
    secondary500: '#F7B89E',
    otherSpecial2: '#92D5EB',

    libertyYellow: '#FFD000',
    libertyTeal: '#78e1e1',
    libertyBlue: '#1A1446',
    libertyAtmosphericWhite: '#F5F5F5',
    libertyGray29: '#c0bfc0',
    libertyGray10: '#e6e6e6',
    libertyMediumTeal: '#28A3AF',
    libertyDarkTeal: '#06748C',

    level1: '#FF8306',
    level2: '#DFD7CD',
    level3: '#3dc1d3',
    level4: '#3E4178',

    // backgroundColor1: '#F1EEEA',
    // backgroundColor2: '#197A56',
    // backgroundColor80: 'rgba(25, 122, 86, 0.8)',
    textColor1: '#000000',
    textColor2: '#D4D4D4',
    tutorialBackground: '#E9EAEC',

    

    // primaryButton: '#21BF61',

    

    startingGuideBackground: '#C4B5A4',

    
};
