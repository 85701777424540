import defaultMiddleImage from '../assets/people-intro.png';
import { colors, fonts, containerStyles, breakpoints } from '../styles';
import isSSO from '../utils/checkIfSSOType';
import { globalColor } from '../utils/globals';
import useViewport from '../utils/useViewport';
import Button from './Button';
import InformationLayout from './InformationLayout';
import ProgressControls from './ProgressControls';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

interface DisclaimerPageTemplateProps {
    title?: string;
    tagline?: string;
    content: string | React.ReactElement;
    nextPath?: string;
    previousPath?: string;
    nextText?: string;
    previousText?: string;
    middleImage?: string;
    logoImage?: string;
    breakpoints?: number;
    leftSize?: number;
    leftSideBackgroundColor?: string;
    rightSideBackgroundColor?: string;
    canOverflow?: boolean;
    shouldNavForward?: boolean;
    buttonDisabled?: boolean;
    onButtonClick?: () => void;
    controls?: boolean;
    levels_state?: any;
    children?: React.ReactNode;
    uid: any;
    survey_id: any;
    validateEmailText?: any;
    helpEmailText?: any;
    emailPlaceholder?: any;
}

const Input = styled.input.attrs(() => ({
    type: 'text',
}))`
    appearance: none;
    border: 1px solid ${colors.libertyGray10};
    border-radius: 8px;
    background: white;
    color: ${colors.black};
    width: 30%;
    height: 44px;
    padding: 15px;
    font-size: 13px;
    box-sizing: border-box;
    ${breakpoints.onlyMobile} {
        width: 100%;
    }
`;

// const Input = styled.input.attrs(() => ({
//     type: 'text',
// }))`
//     appearance: none;
//     border: 1px solid ${colors.white20};
//     border-radius: 8px;
//     background: white;
//     color: ${colors.textColor1};
//     width: 100%;
//     height: 50px;
//     padding: 15px;
//     font-size: 0.75em;
//     box-sizing: border-box;
// `;

interface ImageSideCSSProps {
    leftSize: number;
}

interface NextStepContainerProps {
    leftSize: number;
}

interface MainContentProps {
    leftSize: number;
    withButton: boolean;
}

const TextContent = styled.div<NextStepContainerProps>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    ${breakpoints.md} {
        padding-right: ${(props) => (props.leftSize === 100 ? 0 : 60)}px;
    }
    ${breakpoints.lg} {
        padding-right: ${(props) => (props.leftSize === 100 ? 0 : 60)}px;
    }
    > * {
        &:nth-child(1) {
            margin-right: 20px;
        }
    }
`;

const ImageSide = styled.div<ImageSideCSSProps>`
    display: flex;
    align-items: center;
    position: relative;
    width: ${(props) => 100 - props.leftSize}%;
`;

const ImageSi = styled.div<ImageSideCSSProps>`
    // display: flex;
    // align-items: center;
    // position: relative;
    width: ${(props) => 100 - 80}%;
`;

const Title = styled.div`
    ${fonts.title};
    color: ${globalColor.titleColor};
    text-transform: uppercase;
    margin-bottom: 32px;
`;

const Tagline = styled.div`
    ${fonts.subtitle};
    color: ${colors.textColor1};
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
`;

const Content = styled.div`
    color: ${colors.textColor1};
    margin-top: 32px;
    font-size: 18px;
    line-height: 1.75;

    & a {
        color: ${globalColor.linkColor};
    }
    ${breakpoints.onlyMobile} {
        font-size: 1em;
    }
    ${breakpoints.md} {
        font-size: 18px;
    }
`;

const MiddleImage = styled.img`
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    top: 200px;
`;

const NextStepContainer = styled.div<NextStepContainerProps>`
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    text-decoration: none;
    margin: 1em 0 1em 0;
`;

const MainContent = styled.div<MainContentProps>`
    display: flex;
    > * {
        &:nth-child(1) {
            ${breakpoints.onlyMobile} {
                width: 100%;
                padding-right: 0;
            }
            width: ${(props) => props.leftSize}%;
            padding-right: 60px;
        }
        &:nth-child(2) {
            height: ${(props) =>
                props.withButton
                    ? containerStyles.maxHeight - containerStyles.nextStepHeight
                    : containerStyles.maxHeight}px;
        }
    }
`;

const PositionnedProgressControls = styled(ProgressControls)`
    position: absolute;
    top: 0;
    right: 0;
    width: 90px;
`;

const PositionnedProgressContro = styled(ProgressControls)`
    position: absolute;
    top: 0;
    right: -350px;
    width: 90px;
`;

const HelpEmailText = styled.div`
    color: red;
    font-size: 13px;
    margin: 0.2em 0 0 0;
    width: 30%;
    word-wrap: break-word;
    ${breakpoints.onlyMobile} {
        width: 100%;
    }
`;

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const DisclaimerPageTemplate: React.FC<DisclaimerPageTemplateProps> = ({
    title,
    levels_state,
    tagline,
    content,
    previousPath,
    nextPath,
    nextText,
    previousText,
    middleImage = defaultMiddleImage,
    logoImage,
    leftSize = 55,
    leftSideBackgroundColor = globalColor.backgroundColor1,
    rightSideBackgroundColor = globalColor.backgroundColor2,
    children,
    shouldNavForward = true,
    canOverflow = false,
    // buttonDisabled = false,
    controls = true,
    // onButtonClick,
    uid,
    survey_id,
    validateEmailText,
    helpEmailText,
    emailPlaceholder,
}): React.ReactElement => {
    const { width } = useViewport();
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const [emailAuth, setEmailAuth] = useState(false);
    const [userUid, setUserUid] = useState('');
    const formRef: any = useRef(null);

    const [emailvalid, setEmailValid] = useState(true);

    async function fetchConfig(emailAddress: any) {
        const response = await fetch(`${backendUrl}/validate_email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                adid: uid,
            },
            body: JSON.stringify({ email: emailAddress }),
        });
        const data = await response.json();
        const valid = data && data === true;
        setButtonDisabled(!valid);
        setEmailValid(valid);
        setEmailAuth(valid);
    }

    const onButtonClick = () => {
        const inputValue = formRef.current.elements['emailInput'].value;
        fetchConfig(inputValue);
    };

    useEffect(() => {
        async function fetchUserId() {
            const response = await fetch(`${backendUrl}/user/uid`);
            const data = await response.json();
            setUserUid(data);
        }
        if (isSSO()) {
            setButtonDisabled(false);
            setEmailValid(true);
            setEmailAuth(emailvalid);
            fetchUserId();
        }
    }, []);
    return !buttonDisabled ? (
        <React.Fragment>
            <InformationLayout
                leftSize={leftSize}
                leftSideBackgroundColor={leftSideBackgroundColor}
                rightSideBackgroundColor={rightSideBackgroundColor}
                withButton={!!nextText}
                canOverflow={canOverflow}
                logoImage={logoImage}
            >
                <MainContent withButton={!!nextPath} leftSize={leftSize}>
                    <TextContent leftSize={leftSize}>
                        {/* {title && <Title>{title}</Title>} */}
                        {tagline && <Tagline>{tagline}</Tagline>}
                        {(typeof content === 'string' && (
                            <Content
                                dangerouslySetInnerHTML={{
                                    __html: content as string,
                                }}
                            />
                        )) || <Content>{content}</Content>}
                        {children}
                    </TextContent>
                    {width > breakpoints.value.mobile && (
                        <ImageSide leftSize={leftSize}>
                            {/* {controls && (
                    <PositionnedProgressControls
                        backPath={previousPath}
                        emailAuth={emailAuth}
                        forwardPath={
                            (!buttonDisabled &&
                                shouldNavForward &&
                                nextPath) ||
                            undefined
                        }
                    />
                )} */}
                            {/* {controls && (
                    <PositionnedProgressContro>
                    fsdf
                    </PositionnedProgressContro>
                )} */}
                            <MiddleImage src={middleImage} />
                        </ImageSide>
                    )}
                </MainContent>
                {nextPath && (
                    <NextStepContainer leftSize={leftSize}>
                        <NavLink
                            to={
                                userUid
                                    ? `${survey_id}/${userUid}/${nextPath}`
                                    : nextPath
                            }
                            state={{ emailAuth: emailAuth }}
                        >
                            <Button disabled={buttonDisabled}>
                                {nextText}
                            </Button>
                        </NavLink>
                    </NextStepContainer>
                )}
            </InformationLayout>
        </React.Fragment>
    ) : (
        <React.Fragment>
            <InformationLayout
                leftSize={leftSize}
                leftSideBackgroundColor={leftSideBackgroundColor}
                rightSideBackgroundColor={rightSideBackgroundColor}
                withButton={!!nextText}
                canOverflow={canOverflow}
                logoImage={logoImage}
            >
                <MainContent withButton={!!nextPath} leftSize={leftSize}>
                    <TextContent leftSize={leftSize}>
                        {/* {title && <Title>{title}</Title>} */}
                        {tagline && <Tagline>{tagline}</Tagline>}
                        {(typeof content === 'string' && (
                            <Content
                                dangerouslySetInnerHTML={{
                                    __html: content as string,
                                }}
                            />
                        )) || <Content>{content}</Content>}
                        {children}
                    </TextContent>
                    {width > breakpoints.value.mobile && (
                        <ImageSide leftSize={leftSize}>
                            {/* {controls && (
                            <PositionnedProgressControls
                                backPath={previousPath}
                                emailAuth={emailAuth}
                                forwardPath={
                                    (!buttonDisabled &&
                                        shouldNavForward &&
                                        nextPath) ||
                                    undefined
                                }
                            />
                        )} */}
                            {/* {controls && (
                            <PositionnedProgressContro>
                            fsdf
                            </PositionnedProgressContro>
                        )} */}
                            <MiddleImage src={middleImage} />
                        </ImageSide>
                    )}
                </MainContent>
                <form
                    ref={formRef}
                    onSubmit={(e) => {
                        if (buttonDisabled) {
                            e.preventDefault(); // <-- prevent the default form action
                            onButtonClick();
                        }
                    }}
                >
                    <Input
                        name="emailInput"
                        placeholder={emailPlaceholder || ''}
                    />
                </form>
                {!emailvalid && (
                    <HelpEmailText
                        dangerouslySetInnerHTML={{ __html: helpEmailText }}
                    />
                )}
                {nextPath && (
                    <NextStepContainer leftSize={leftSize}>
                        {buttonDisabled ? (
                            <Button onClick={onButtonClick}>
                                {validateEmailText}
                            </Button>
                        ) : (
                            <NavLink
                                to={nextPath}
                                state={{ emailAuth: emailAuth }}
                            >
                                <Button
                                    disabled={buttonDisabled}
                                >
                                    {nextText}
                                </Button>
                            </NavLink>
                        )}
                    </NextStepContainer>
                )}
            </InformationLayout>
        </React.Fragment>
    );
};

export default DisclaimerPageTemplate;