function hexToRGBA(hex: string, opacity: number): string {
  let r = 0, g = 0, b = 0;

  // 3 digits
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  }
  // 6 digits
  else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

function lightenHexColor(hex: string, percent: number): string {
  // Ensure percent is within 0 to 100
  percent = Math.min(100, Math.max(0, percent));

  // Convert hex to RGB
  let r = parseInt(hex.substring(1, 3), 16);
  let g = parseInt(hex.substring(3, 5), 16);
  let b = parseInt(hex.substring(5, 7), 16);

  // Calculate the adjustment value
  let adjust = (val: number, percent: number): number => {
    return Math.min(255, Math.round(val + (255 - val) * (percent / 100)));
  };

  // Adjust and reassemble RGB values into a hex string
  r = adjust(r, percent);
  g = adjust(g, percent);
  b = adjust(b, percent);

  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}

export {
  hexToRGBA,
  lightenHexColor
}