import { getFeedback } from '../actions/feedbackActions';
import { getSurvey } from '../actions/surveyActions';
import { breakpoints, colors, fonts } from '../styles';
import CircleIcon from './CircleIcon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import type {} from 'redux-thunk/extend-redux';
import styled from 'styled-components';

const LanguageContentWrapper = styled.div<{ show: boolean }>`
    display: ${(props) => (props.show ? 'block' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${colors.white};
    border-radius: 5px;
    ${fonts.indication};
    box-sizing: border-box;
    padding: 30px;
    z-index: 4;
    outline: none;
`;

const LanguageMenuWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

const LanguageItemStyle = `
padding-left: 10px;
background-image: url('${(props: { icon: any }) => props.icon}');
background-repeat: no-repeat;
background-position-y: center;
background-position-x: 5px;

cursor: pointer;`;

const LanguageItem = styled.div<{ icon?: string }>`
    ${LanguageItemStyle}
`;

const LanguageText = styled.div`
    white-space: nowrap;
`;

const LanguageTextTitle = styled.div`
    color: ${colors.black};
    text-transform: uppercase;
    font-weight: bold;
`;

const LanguageMenuLabel = styled.div`
    font-size: 18px;
    display: inline-block;
    vertical-align: middle;
    color: ${colors.textColor1};
    margin-left: 5px;
    cursor: pointer;

    height: 14px;
    ${breakpoints.md} {
        height: 34px;
    }
`;

const StyledNavLink = styled.div`
    text-decoration: none;
    font-size: 18px;
    display: block;
    margin-bottom: 23px;
    &:last-child {
        margin-bottom: 0;
    }
    // ${breakpoints.onlyMobile} {
    //     width: 30px !important;
    //     height: 30px !important;
    // }
`;

const PositionedCircleIcon = styled(CircleIcon)`
    position: relative;
    z-index: 20;
    display: inline-block;
    width: 36px;
    height: 36px;
`;

interface LanguageSwitcherProps {
    survey_id: any;
    uid: any;
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
    survey_id,
    uid,
}) => {
    const { t, i18n } = useTranslation(['LanguageSwitcher']);
    const [showMenu, setShowMenu] = useState(false);

    const dispatch = useDispatch();

    const changeTo = async (lang: string) => {
        await i18n.changeLanguage(lang);
        dispatch(getSurvey(survey_id, uid));
        dispatch(getFeedback(uid));
        setShowMenu(!showMenu);
    };

    return (
        <LanguageMenuWrapper>
            <PositionedCircleIcon
                type={showMenu ? 'close' : 'lang'}
                active={true}
                backgroundColor={showMenu ? colors.white : colors.white}
                onClick={() => {
                    setShowMenu(!showMenu);
                }}
            />
            <LanguageMenuLabel
                onClick={() => {
                    setShowMenu(!showMenu);
                }}
            >
                {t('language')}
            </LanguageMenuLabel>
            <LanguageContentWrapper show={showMenu} tabIndex={-1}>
                <StyledNavLink onClick={async () => await changeTo('en')}>
                    <LanguageItem>
                        <LanguageText>
                            <LanguageTextTitle>English</LanguageTextTitle>
                        </LanguageText>
                    </LanguageItem>
                </StyledNavLink>
                <StyledNavLink onClick={async () => await changeTo('es')}>
                    <LanguageItem>
                        <LanguageText>
                            <LanguageTextTitle>Spanish (EU)</LanguageTextTitle>
                        </LanguageText>
                    </LanguageItem>
                </StyledNavLink>
                <StyledNavLink onClick={async () => await changeTo('fr')}>
                    <LanguageItem>
                        <LanguageText>
                            <LanguageTextTitle>French</LanguageTextTitle>
                        </LanguageText>
                    </LanguageItem>
                </StyledNavLink>
                <StyledNavLink onClick={async () => await changeTo('pt')}>
                    <LanguageItem>
                        <LanguageText>
                            <LanguageTextTitle>Portuguese</LanguageTextTitle>
                        </LanguageText>
                    </LanguageItem>
                </StyledNavLink>
            </LanguageContentWrapper>
        </LanguageMenuWrapper>
    );
};

export default LanguageSwitcher;
