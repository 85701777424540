import { debugResetApp } from '../actions/surveyActions';
import Button from '../components/Button';
import InformationPageTemplate from '../components/InformationPageTemplate';
//import { withRouter } from "react-router";
import { breakpoints, colors } from '../styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { State } from '../redux-state';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const StyledButton = styled(Button)`
    margin-top: 1em;
    margin-right: 20px;
`;

const Buttons = styled.div`
    display: flex;
    ${breakpoints.onlyMobile} {
        flex-direction: column;
    }
`;

const WhatsNextPage: React.FC = (): React.ReactElement => {
    const dispatch = useDispatch();
    const { t } = useTranslation(['WhatsNext']);

    const { survey_id, uid } = useParams();

    const resetMySurvey = () => {
        if (window.confirm(t('resetQuestionText') || '')) {
            dispatch(debugResetApp(survey_id, uid));
            navigate(`/${survey_id}/${uid}`);
        }
    };

    const [client_name, setClientName] = useState<any>('');
    useEffect(() => {
        async function fetchConfig() {
            const response = await fetch(`${backendUrl}/get_client_info`,{
                headers: {
                    'Survey-Id': survey_id || ''
                }
            });
            const data = await response.json();
            setClientName(data.rows[0].client);
        }
        fetchConfig();
    }, []);
    // const old_content = t('content');

    // const new_subtitle = client_name.replace(/_/g, ' ');
    const updatedContent = t('content', { CLIENT: client_name });
    const newContent = updatedContent.replace(/_/g, ' ');

    let emailAuth;
    let levels_state;
    const navigate = useNavigate();
    const location = useLocation();
    const stateVar = location.state;

    if (stateVar) {
        if ('levels_state' in stateVar) {
            levels_state = stateVar.levels_state;
        }
        if ('emailAuth' in stateVar) {
            emailAuth = stateVar.emailAuth;
        }
    }

    useEffect(() => {
        if (!stateVar) {
            navigate(`/${survey_id}/${uid}`, { replace: true });
        }
    }, []);

    const retakeFeatureEnabled: boolean = useSelector(
        (state: State): boolean  => state.surveyState.retakeFeatureEnabled || false,
    );

    return !stateVar ? (
        <></>
    ) : (
        <InformationPageTemplate
            tagline={t('title') || ''}
            content={newContent || ''}
            middleImage={t('image') || ''}
            nextText="no-next"
            previousPath={`/${survey_id}/${uid}/survey-welcome`}
            emailAuth={emailAuth}
            controls={false}
        >
            <Buttons>
                {/* <StyledButton onClick={() => dispatch(getSummaryPDF(uid))}>
                    {t('downloadButtonText')}
                </StyledButton> */}
                {retakeFeatureEnabled && <StyledButton
                    backgroundColor={colors.red}
                    primaryButtonHover={colors.redHover}
                    onClick={() => resetMySurvey()}
                >
                    {t('resetAppText')}
                </StyledButton>}
            </Buttons>
        </InformationPageTemplate>
    );
};

export default WhatsNextPage;
