import { colors } from '../styles';
import React from 'react';
import styled from 'styled-components';
import {hexToRGBA} from '../utils/colorUtil';
import { globalColor } from '../utils/globals';

interface LoaderProps {
    shown: boolean;
}

const LoaderWrapper = styled.div<LoaderProps>`
    position: absolute;
    z-index: 2;

    display: block;

    width: 100%;
    height: 100%;
    margin: auto;

    transition: opacity 0.2s linear;
    pointer-events: none;

    opacity: ${(props) => (props.shown ? 1 : 0)};
    background-color: ${_ => hexToRGBA(globalColor.backgroundColor2, 80)};
`;

const Spinner = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;

    display: block;

    width: 125px;
    height: 125px;
    margin: auto;
    margin-top: -125px;

    transform: translate(-50%, -50%);
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;

    border: 4px solid transparent;
    border-top: 4px solid ${colors.blue};
    border-radius: 50%;
    &:before,
    &:after {
        position: absolute;
        content: '';
        border: 4px solid transparent;
        border-radius: 50%;
    }
    &:before {
        top: 5px;
        right: 5px;
        bottom: 5px;
        left: 5px;

        animation: spin 3.5s linear infinite;

        border-top-color: #3498db;
    }
    &:after {
        top: 15px;
        right: 15px;
        bottom: 15px;
        left: 15px;

        animation: spin 1.75s linear infinite;

        border-top-color: ${colors.lightGray};
    }
`;

const Loader: React.FC<LoaderProps> = ({ shown }): React.ReactElement => (
    <LoaderWrapper shown={shown}>
        <Spinner />
    </LoaderWrapper>
);

export default Loader;
