import FeedbackQuestion from '../components/FeedbackQuestion';
import InformationPageTemplate from '../components/InformationPageTemplate';
import { Feedback } from '../model/models';
import { State } from '../redux-state';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

const FeedbackQuestionPage: React.FC = (): React.ReactElement => {
    const { t } = useTranslation(['FeedbackQuestion']);
    const { survey_id, uid } = useParams();
    let emailAuth;
    const location = useLocation();
    const stateVar = location.state;
    if (stateVar) {
        if ('emailAuth' in stateVar) {
            emailAuth = stateVar.emailAuth;
        }
    }
    const data = useSelector((state: State): Feedback => state.feedbackState);

    return (
        <InformationPageTemplate
            tagline={t('title') || ''}
            content={''}
            middleImage={t('image') || ''}
            nextText="no-next"
            previousPath={`/${survey_id}/${uid}/survey-welcome`}
            emailAuth={emailAuth}
            controls={false}
        >
            <FeedbackQuestion
                questions={data.questions}
                answers={data.answers}
            ></FeedbackQuestion>
        </InformationPageTemplate>
    );
};

export default FeedbackQuestionPage;
