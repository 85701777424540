import defaultMiddleImage from '../assets/people-intro.png';
import useViewport from '../utils/useViewport';
import InformationPageTemplate from './InformationPageTemplate';
import JobHelpPagePopin from './JobHelpPagePopin';

interface MoreInfoTemplateProps {
    title?: string;
    tagline?: string;
    content: string | React.ReactElement;
    previousPath?: string;
    nextText?: string;
    previousText?: string;
    middleImage?: string;
    canOverflow?: boolean;
    shouldNavForward?: boolean;
    buttonDisabled?: boolean;
    onButtonClick?: () => void;
    logoImage?: string;
    emailAuth?: any;
    showJobHelp: any;
    setShowJobHelp: any;
}


const MoreInfoTemplate: React.FC<MoreInfoTemplateProps> = ({
    title,
    tagline,
    content,
    previousPath,
    nextText,
    previousText,
    middleImage = defaultMiddleImage,
    logoImage,
    shouldNavForward = true,
    buttonDisabled = false,
    showJobHelp,
    setShowJobHelp,
    onButtonClick,
    emailAuth,
}): React.ReactElement => {
    const { width } = useViewport();
    // const [showHelp, setShowHelp] = useState(false);

    return showJobHelp ? (
        <JobHelpPagePopin onClose={() => setShowJobHelp(false)} />
      ): (
        <InformationPageTemplate
            title={title}
            tagline={tagline}
            content={content}
            nextText={nextText}
            previousText={previousText}
            previousPath={previousPath}
            middleImage={middleImage}
            canOverflow={true}
            shouldNavForward={shouldNavForward}
            buttonDisabled={buttonDisabled}
            onButtonClick={onButtonClick}
            logoImage={logoImage}
            emailAuth={emailAuth}
    />
    );
};

export default MoreInfoTemplate;