import { getFeedback } from '../actions/feedbackActions';
import {
    getSurvey,
    firstTimeLoading,
    getApplicationSettings,
} from '../actions/surveyActions';
import Text from '../assets/page-text/en/Miscellaneous.json';
import LanguageSwitcher from '../components/LanguageSwitcher';
import Loader from '../components/Loading';
import { State } from '../redux-state';
import { breakpoints, colors, fonts } from '../styles';
import isSSO from '../utils/checkIfSSOType';
import { hexToRGBA } from '../utils/colorUtil';
import { globalColor } from '../utils/globals';
import DebugResetPage from './DEBUG_ResetPage';
import DisclaimerPage from './DisclaimerPage';
import HelpPage from './HelpPage';
import IntroductionPage from './IntroductionPage';
import MissingPage from './MissingPage';
import MoreInfoPage from './MoreInfoPage';
import PresurveyPage from './PresurveyPage';
import SummaryPage from './SummaryPage';
import SurveyFeedbackPage from './SurveyFeedbackPage';
import SurveyPage from './SurveyPage';
import SurveyWelcomePage from './SurveyWelcomePage';
import TutorialPage from './TutorialPage';
import WhatsNextPage from './WhatsNextPage';
import React, { useEffect, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

const BetaIndicator = styled.div`
    position: absolute;
    width: 100%;
    justify-content: center;
    background-color: ${(_) => hexToRGBA(globalColor.backgroundColor2, 80)};
    ${fonts.content};
    color: ${colors.white};
    height: 50px;
    display: flex;
    align-items: center;
    left: 0;
    z-index: 1000;
`;

const ErrorToast = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    background-color: ${(_) => globalColor.backgroundColor2};
    ${fonts.content};
    color: ${colors.white};
    display: flex;
    z-index: 9999;
    align-items: center;
`;

const getDefaultRoutes = (introDone: boolean, rgpdAccepted: boolean): any => {
    return (
        <Routes>
            <Route
                path="/:survey_id/:uid"
                element={isSSO() ? <Navigate to="/" /> : <DisclaimerPage />}
            />
            {/* <Route path="/" element={<DisclaimerPage />} /> */}
            <Route
                path={'/:survey_id/:uid/introduction'}
                element={<IntroductionPage />}
            />
            {/* <Route
                path={'/change_survey/:database_name'}
                element={<ChangeDomain />}
            />
            <Route
                path={'/create_survey/:database_name'}
                element={<ChangeDomain />}
            /> */}

            <Route
                path="/:survey_id/:uid/more-info"
                element={<MoreInfoPage />}
            />

            {/* <Route path="/more-info" element={<MoreInfoPage />}>
                {!rgpdAccepted && <Navigate to="/introduction" />}
            </Route> */}

            {/* <Route path="/starting-guide" element={<DisclaimerPage />}>
                {!rgpdAccepted && <Navigate to="/introduction" />}
            </Route> */}

            <Route
                path="/starting-guide"
                element={
                    !rgpdAccepted ? (
                        <Navigate to="/:survey_id/:uid/introduction" />
                    ) : (
                        <DisclaimerPage />
                    )
                }
            />

            {/* <Route path="/tutorial" element={<TutorialPage />}>
                {!rgpdAccepted && <Navigate to="/introduction" />}
            </Route> */}

            <Route
                path="/:survey_id/:uid/tutorial"
                element={
                    !rgpdAccepted ? (
                        <Navigate to="/:survey_id/:uid/introduction" />
                    ) : (
                        <TutorialPage />
                    )
                }
            />

            {/* <Route path="/pre-survey" element={<PresurveyPage />}>
                {!rgpdAccepted && <Navigate to="/introduction" />}
            </Route> */}

            <Route
                path="/:survey_id/:uid/pre-survey"
                element={
                    !rgpdAccepted ? (
                        <Navigate to="/:survey_id/:uid/introduction" />
                    ) : (
                        <PresurveyPage />
                    )
                }
            />

            {/* <Route path="/summary" element={<SummaryPage />}>
                {!rgpdAccepted && <Navigate to="/introduction" />}
            </Route> */}

            <Route
                path="/:survey_id/:uid/summary"
                element={
                    !rgpdAccepted ? (
                        <Navigate to="/:survey_id/:uid/introduction" />
                    ) : (
                        <SummaryPage />
                    )
                }
            />

            {/* <Route path="/help" element={<HelpPage />}>
                {!rgpdAccepted && <Navigate to="/introduction" />}
            </Route> */}

            <Route
                path="/:survey_id/:uid/help"
                element={
                    !rgpdAccepted ? (
                        <Navigate to="/:survey_id/:uid/introduction" />
                    ) : (
                        <HelpPage />
                    )
                }
            />

            {/* <Route path="/survey-welcome" element={<SurveyWelcomePage />}>
                {!rgpdAccepted && <Navigate to="/introduction" />}
            </Route> */}

            <Route
                path="/:survey_id/:uid/survey-welcome"
                element={
                    !rgpdAccepted ? (
                        <Navigate to="/:survey_id/:uid/introduction" />
                    ) : (
                        <SurveyWelcomePage />
                    )
                }
            />

            <Route
                path="/:survey_id/:uid/feedback"
                element={
                    !rgpdAccepted ? (
                        <Navigate to="/:survey_id/:uid/introduction" />
                    ) : (
                        <SurveyFeedbackPage />
                    )
                }
            />

            {/* <Route path="/survey/:domain" element={<SurveyPage />}>
                {!rgpdAccepted && <Navigate to="/introduction" />}
            </Route> */}

            <Route
                path="/:survey_id/:uid/survey/:domainId"
                element={
                    !rgpdAccepted ? (
                        <Navigate to="/:survey_id/:uid/introduction" />
                    ) : (
                        <SurveyPage />
                    )
                }
            />

            {/* <Route path="/whatsnext" element={<WhatsNextPage />}>
                {!rgpdAccepted && <Navigate to="/introduction" />}
            </Route> */}

            <Route
                path="/:survey_id/:uid/whatsnext"
                element={
                    !rgpdAccepted ? (
                        <Navigate to="/:survey_id/:uid/introduction" />
                    ) : (
                        <WhatsNextPage />
                    )
                }
            />

            {/* <Route path="/reset" element={<DebugResetPage />}>
                {!rgpdAccepted && <Navigate to="/introduction" />}
            </Route> */}

            <Route
                path="/:survey_id/:uid/reset"
                element={
                    !rgpdAccepted ? (
                        <Navigate to="/:survey_id/:uid/introduction" />
                    ) : (
                        <DebugResetPage />
                    )
                }
            />

            <Route
                path="/"
                element={isSSO() ? <DisclaimerPage /> : <MissingPage />}
            />
            <Route path="*" element={<Navigate to="/missing" replace />} />
            <Route path="/missing" element={<MissingPage />} />
        </Routes>
    );
};

const ErrorToastContent = styled.div``;

const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@100;400&display=swap');
    * {
        font-family: 'Open Sans', sans-serif;
    }
    html, body {
        height: 100%;
        margin: 0;
        ${breakpoints.onlyMobile}{
            font-size: 16px;
        }
         ${breakpoints.sm} {
            font-size: 16px;
         }
        ${breakpoints.md} {
            font-size: 19px;
        }
        ${breakpoints.lg}{
            font-size: 24px;
        }
        ${breakpoints.largeDesktop}{
          font-size: 24px;
        }
       ${breakpoints.largerDesktop} {
        font-size: 24px;
        }
    }
    #root {
        min-width: 1200px;
        ${breakpoints.onlyMobile}{
            min-width: 100%;
        }
        min-height: 100%;
        display: flex;
        position: relative;
        overflow-y: auto;
    }
    /*
        Remove arrow in select for IE.
     */
    select::-ms-expand {
        display: none;
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;

const LanguageSwitcherWrapper = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
`;

const App: React.FC = (): React.ReactElement => {
    const dispatch = useDispatch();

    const currentRoute = window.location.pathname;

    const matches = currentRoute.match(/^\/([^/]+)/);
    const survey_id = matches ? matches[1] : null;

    const matches2 = currentRoute.match(/^\/[^/]+\/([^/]+)/);
    const uid = matches2 ? matches2[1] : null;

    const [project_name, setProjectName] = useState<any>('');

    useEffect(() => {
        dispatch(getApplicationSettings());
        async function fetchConfig() {
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/get_client_info`,
            );
            const data = await response.json();
            setProjectName(data.rows[0].project_name);
            dispatch(getFeedback(uid));
        }
        fetchConfig();
        dispatch(getSurvey(survey_id, uid));
        dispatch(firstTimeLoading(true));
    }, []);

    const { t } = useTranslation('Miscellaneous');

    const introDone = useSelector(
        (state: State): boolean => state.surveyState.presurveyCompleted,
    );

    const rgpdAccepted = useSelector(
        (state: State): boolean => !!state.surveyState.userInfos.gdprAccepted,
    );

    const loading = useSelector(
        (state: State): boolean => state.surveyState.firstTimeLoading,
    );

    const error = useSelector(
        (state: State): string | undefined => state.surveyState.error,
    );

    const surveyClosed = useSelector(
        (state: State): boolean | undefined => state.surveyState.surveyClosed,
    );
    const multiLingualEnabled = useSelector(
        (state: State): boolean =>
            state.surveyState.multiLingualEnabled || false,
    );

    const beta = false;
    if (loading)
        return (
            <Fragment>
                <GlobalStyle />
                <Loader shown={loading}></Loader>
            </Fragment>
        );
    return (
        <Fragment>
            {(error || (uid && survey_id != project_name)) && !surveyClosed && (
                <ErrorToast>
                    <ErrorToastContent
                        dangerouslySetInnerHTML={{
                            __html: t('errorMessage'),
                        }}
                    />
                </ErrorToast>
            )}

            {surveyClosed && (
                <ErrorToast>
                    <ErrorToastContent
                        dangerouslySetInnerHTML={{
                            __html: t('errorSurveyClosed'),
                        }}
                    />
                </ErrorToast>
            )}

            {beta && (
                <BetaIndicator
                    style={{
                        top: 0,
                    }}
                >
                    {Text.betaTestToast}
                </BetaIndicator>
            )}
            <GlobalStyle />

            {!error && (
                <BrowserRouter>
                    {getDefaultRoutes(introDone, rgpdAccepted)}
                    {multiLingualEnabled && (
                        <LanguageSwitcherWrapper>
                            <LanguageSwitcher survey_id={survey_id} uid={uid} />
                        </LanguageSwitcherWrapper>
                    )}
                </BrowserRouter>
            )}
            {beta && (
                <BetaIndicator
                    style={{
                        bottom: 0,
                    }}
                >
                    {Text.betaTestToast}
                </BetaIndicator>
            )}
        </Fragment>
    );
};

export default App;
