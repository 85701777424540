import { colors, fonts, containerStyles } from '../styles';
import React from 'react';
import styled from 'styled-components';

interface ClickableDomain {
    image: string;
    title: string;
    progress: number;
    total: number;
    score?: number | undefined;
}

const CliclableDomainWrapper = styled.div`
    display: flex;
    padding: 15px 25px;
    border-radius: 4px;
    border: 1px solid ${colors.libertyGray10};
    justify-content: space-between;
    background-color: ${colors.white};
    align-items: center;
`;

const Image = styled.div<{ url?: string }>`
    height: 50px;
    min-height: 50px;
    width: 50px;
    min-width: 50px;
    background-color: ${colors.blue30};
    border-radius: 50px;
    margin-right: 10px;
    background-image: url('${(props) => props.url}');
    ${containerStyles.containNoRepeatBackground}
`;

const Progress = styled.div<{ done: boolean }>`
    padding: 3px 3px 5px 3px;
    box-sizing: border-box;
    font-size: 12px;
    height: 26px;
    width: 40px;
    border-radius: 4px;
    color: ${colors.white};
    align-items: center;
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    background-color: ${(props) =>
        props.done ? colors.blueText : colors.secondary500};
`;

const ProgressText = styled.div``;

const Title = styled.div`
    ${fonts.content}
    font-weight: bold;
    color: ${colors.black};
`;

const ImageTitle = styled.div`
    display: flex;
    align-items: center;
`;

const ClickableDomain: React.FC<ClickableDomain> = ({
    image,
    title,
    progress,
    total,
    score,
}) => (
    <CliclableDomainWrapper>
        <ImageTitle>
            <Image url={image} />
            <Title
                dangerouslySetInnerHTML={{
                    __html: title,
                }}
            />
        </ImageTitle>

        <Progress done={total === progress}>
            <ProgressText>
                {progress === total ? score || 'Done' : `${progress}/${total}`}
            </ProgressText>
        </Progress>
    </CliclableDomainWrapper>
);

export default ClickableDomain;
