import { acceptGDPR } from '../actions/surveyActions';
import IntroductionPageTemplate from '../components/IntroductionPageTemplate';
import { State } from '../redux-state';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';

// import { Redirect } from 'react-router-dom';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const IntroductionPage: React.FC = (): React.ReactElement => {
    const { t } = useTranslation(['Introduction']);
    const dispatch = useDispatch();

    const { survey_id, uid } = useParams();

    const rgpdAcceptedServerSide: boolean = useSelector(
        (state: State): boolean => !!state.surveyState.userInfos.gdprAccepted,
    );

    const loading: boolean = useSelector(
        (state: State): boolean => !!state.surveyState.loading,
    );

    const previousRGPDAccepted = useRef(true);

    useEffect(() => {
        if (previousRGPDAccepted.current === false && rgpdAcceptedServerSide) {
            return navigate(`/${survey_id}/${t('nextViewSlug')}`);
        }
        if (!loading) previousRGPDAccepted.current = !!rgpdAcceptedServerSide;
    }, [rgpdAcceptedServerSide, loading]);

    const [client_name, setClientName] = useState<any>('');
    useEffect(() => {
        async function fetchConfig() {
            const response = await fetch(`${backendUrl}/get_client_info`, {
                headers: {
                    'Survey-Id': survey_id || ''
                }
            });
            const data = await response.json();
            setClientName(data.rows[0].client);
        }
        fetchConfig();
    }, []);
    // const old_content = t('content');

    const updatedContent = t('dropdown-content', { CLIENT: client_name });
    const newContent = updatedContent.replace(/_/g, ' ');

    let emailAuth;
    const navigate = useNavigate();
    const location = useLocation();
    const stateVar = location.state;

    if (stateVar) {
        if ('emailAuth' in stateVar) {
            emailAuth = stateVar.emailAuth;
        }
    }

    useEffect(() => {
        if (!stateVar) {
            survey_id;
            navigate(`/${survey_id}/${uid}`, { replace: true });
        }
    }, []);

    return !stateVar ? (
        <></>
    ) : (
        <IntroductionPageTemplate
            // title={t('title') || ''}
            tagline={t('tagline') || ''}
            content={t('content') || ''}
            dropdownTitle={t('dropdown-title') || ''}
            dropdownContent={newContent || ''}
            postDropdownContent={t('post-dropdown-content') || ''}
            conscentContent={t('conscent') || ''}
            nextText={t('nextText') || ''}
            middleImage={t('middleImage') || ''}
            nextPath={`/${survey_id}/${uid}/${t('nextViewSlug')}`}
            onButtonClick={() => dispatch(acceptGDPR(survey_id, uid))}
            shouldNavForward={rgpdAcceptedServerSide}
            emailAuth={emailAuth}
        />
    );
};

export default IntroductionPage;
