import {
    Survey,
    AnswerLevel,
    Domain,
    Subdomain,
    Id,
    ApplicationSettings,
    Feedback,
} from './models';
import Joi from '@hapi/joi';

const IdModel = Joi.number();
const AnswerLevelModel = Joi.number()
    .valid(0, 1, 2, 3, 4, 5, 6)
    .allow(null)
    .optional();

const ApplicationSettingModel = Joi.object({
    staticDomainAverageColor: Joi.string().allow(null),
    feedbackFeatureEnabled: Joi.boolean().allow(null),
    domainDefaultProficiency: Joi.number().allow(null),
    retakeFeatureEnabled: Joi.boolean().allow(null),
    multiLingualEnabled: Joi.boolean().allow(null),
    summaryFeatureEnabled: Joi.boolean().allow(null),
    leftPanelColor: Joi.string(),
    rightPanelColor: Joi.string(),
    logoSrc: Joi.string(),
});

const SubdomainModel = Joi.object({
    id: IdModel,
    idx: Joi.number(),
    title: Joi.string(),
    explanation: Joi.string(),
    targetSample: Joi.string().allow(null),
    level: AnswerLevelModel,
    domain: Joi.string(),
});

const DomainModel = Joi.object({
    id: IdModel,
    idx: Joi.number(),
    title: Joi.string(),
    explanation: Joi.string(),
    targetSample: Joi.string(),
    preEvaluationLevel: AnswerLevelModel,
    completedSubdomains: Joi.number(),
    completed: Joi.boolean(),
    subdomains: Joi.array().items(SubdomainModel),
    subdomainCursor: Joi.number(),
    mandatory: Joi.boolean().allow(null),
});

const UserInfosModel = Joi.object({
    lastAnsweredTime: Joi.number().allow(null),
    gdprAccepted: Joi.number().allow(null),
    surveyCompleted: Joi.number().allow(null),
    moreInfo: Joi.object(),
});

const SurveyModel = Joi.object({
    domains: Joi.array().items(DomainModel),
    domainCursor: Joi.number(),
    presurveyCompleted: Joi.boolean(),
    userInfos: UserInfosModel,
});

const OptionModel = Joi.object({
    id: Joi.number().allow(null),
    name: Joi.string().allow(null),
});

const QuestionModel = Joi.object({
    id: Joi.number().allow(null),
    name: Joi.string().allow(null),
    type: Joi.string().allow(null),
    isMandatory: Joi.boolean().allow(null),
    options: Joi.array().items(OptionModel),
});

const AnswerModel = Joi.object({
    feedbackOptionId: Joi.number().allow(null),
    feedbackQuestionId: Joi.number().allow(null),
    feedback: Joi.string().allow(null),
});

const FeedbackModel = Joi.object({
    // options: Joi.array().items(OptionModel),
    questions: Joi.array().items(QuestionModel),
    answers: Joi.array().items(AnswerModel),
    // type: Joi.array().items(TypeModel),
});

const validateSurvey = (survey: any): Survey =>
    Joi.attempt(survey, SurveyModel);

const validateFeedback = (feedback: any): Feedback =>
    Joi.attempt(feedback, FeedbackModel);

const validateAnswer = (answer: any): AnswerLevel =>
    Joi.attempt(answer, AnswerLevelModel);

const validateDomain = (domain: any): Domain =>
    Joi.attempt(domain, DomainModel);

const validateSubdomain = (subdomain: any): Subdomain =>
    Joi.attempt(subdomain, SubdomainModel);

const validateId = (id: any): Id => Joi.attempt(id, IdModel);

const validateApplicationSettings = (settings: any): ApplicationSettings =>
    Joi.attempt(settings, ApplicationSettingModel, { stripUnknown: true });

export {
    validateSurvey,
    validateAnswer,
    validateDomain,
    validateSubdomain,
    validateId,
    validateApplicationSettings,
    validateFeedback,
};
