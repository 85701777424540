import idxSvgMapping from '../assets/page-text/DomainAndImageMapping.json';
import textMapping from '../assets/page-text/DomainAndTextMapping.json';
import { Domain } from '../model/models';
import { colors } from '../styles';
import DomainBackground from './DomainBackground';
import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

interface IllustationProps {
    survey_id: any;
    levels_state: any;
    uid: any;
    domains: Domain[];
    currentDomainIndex?: number;
    canNavigate?: boolean;
    domainDefaultProficiency?: number;
}

const IllustrationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    // padding-left: 150px;
`;

const IllustrationRow = styled.div`
    display: flex;
    justify-content: center;
    // margin: 0 -15px;
    // padding-left: 150px;
`;

const DomainBubble = styled.div<{ disabled?: boolean }>`
    width: 60px;
    height: 60px;
    padding: 0 5px;
    opacity: ${(props) => (props.disabled ? 0.3 : 1)};
`;

const DomainImage = styled.img`
    height: 60px;
    width: 60px;
    font-size: 10px;
`;

const rowDivision = [3, 4, 5, 6, 6, 5, 4, 3];

const RowDivider: React.FC<{ children?: React.ReactNode }> = ({
    children,
}): React.ReactElement => {
    const childrenArray = React.Children.toArray(children);
    return (
        <Fragment>
            {rowDivision.map((rowSize, index) => (
                <IllustrationRow key={`row-${index}`}>
                    {childrenArray.splice(0, rowSize)}
                </IllustrationRow>
            ))}
        </Fragment>
    );
};

const getBackgroundColor = (
    domainIndex: number,
    mandatory: boolean | null,
    levels_state: any,
    currentDomainIndex?: number,
    domainLevel?: number,
): string => {
    if (mandatory) return colors.otherSpecial2;
    if (domainIndex === currentDomainIndex) return colors.white;
    switch (domainLevel) {
        case 0:
            return levels_state.levels_data[0].color;
        case 1:
            return levels_state.levels_data[1].color;
        case 2:
            return levels_state.levels_data[2].color;
        case 3:
            return levels_state.levels_data[3].color;
        case 4:
            return levels_state.levels_data[4].color;
        case 5:
            return levels_state.levels_data[5].color;
        case 6:
            return levels_state.levels_data[6].color;
        default:
            return colors.white20;
    }
};

const getDomainBubble = (
    domain: Domain,
    levels_state: any,
    currentDomainIndex?: number,
    domainDefaultProficiency?: number,
) => (
    <div>
        <DomainBubble
            disabled={!domain.preEvaluationLevel}
            title={domain.title}
            key={domain.idx}
        >
            <DomainBackground
                color={getBackgroundColor(
                    domain.idx,
                    domain.mandatory || null,
                    levels_state,
                    currentDomainIndex,
                    domainDefaultProficiency && domainDefaultProficiency != -1
                        ? domainDefaultProficiency
                        : domain.preEvaluationLevel,
                )}
            >
                <DomainImage
                    src={idxSvgMapping[domain.idx]}
                    alt={domain.title}
                />
            </DomainBackground>
        </DomainBubble>
        {/* <div style={{ 
        padding: '5px',
        fontSize: '12px', 
        width: '100px', 
        color: 'white',
        overflowWrap: 'break-word' 
        }}>{textMapping[domain.idx]}
        </div> */}
    </div>
);

const Illustration: React.FC<IllustationProps> = ({
    survey_id,
    levels_state,
    uid,
    domains,
    currentDomainIndex,
    canNavigate = false,
    domainDefaultProficiency,
}) => (
    <IllustrationWrapper>
        <RowDivider>
            {domains.map((domain) => {
                if (
                    canNavigate &&
                    domain.preEvaluationLevel &&
                    domain.preEvaluationLevel > 0
                ) {
                    return (
                        <NavLink
                            key={domain.idx}
                            to={`/${survey_id}/${uid}/survey/${domain.id}`}
                            state={{ levels_state: levels_state }}
                        >
                            {getDomainBubble(
                                domain,
                                levels_state,
                                currentDomainIndex,
                                domainDefaultProficiency,
                            )}
                        </NavLink>
                    );
                }

                return getDomainBubble(
                    domain,
                    levels_state,
                    currentDomainIndex,
                    domainDefaultProficiency,
                );
            })}
        </RowDivider>
    </IllustrationWrapper>
);
export default Illustration;
