import arrow from '../assets/svg/arrow.svg';
import arrowWhite from '../assets/svg/arrowWhite.svg';
import close from '../assets/svg/close.svg';
import download from '../assets/svg/download.svg';
import downloadWhite from '../assets/svg/downloadWhite.svg';
import edit from '../assets/svg/edit.svg';
import help from '../assets/svg/help.svg';
import lang from '../assets/svg/lang.svg';
import info from '../assets/svg/info.svg';
import { breakpoints } from '../styles';
import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';

interface CircleIconProps {
    type: CircleType;
    backgroundColor?: string;
    active?: boolean;
    borderColor?: string;
    onClick?: MouseEventHandler;
    className?: string;
}

interface CircleIconPropsCSS {
    image: string;
    opacity: number;
    borderColor: string;
    backgroundColor: string;
    backward: boolean;
    cursor?: string;
}

const getImageFromType = (type: CircleType) => {
    switch (type) {
        case 'close':
            return close;
        case 'next':
            return arrow;
        case 'previous':
            return arrow;
        case 'previousMobile':
            return arrowWhite;
        case 'help':
            return help;
        case 'edit':
            return edit;
        case 'download':
            return download;
        case 'downloadMobile':
            return downloadWhite;
        case 'lang':
            return lang;
        case 'info':
            return info;
        default:
            return help;
    }
};

const CircleIconWrapper = styled.div<CircleIconPropsCSS>`
    width: 34px;
    height: 34px;
    border-radius: 35px;
    background-image: ${(props) => `url('${props.image}')`};
    background-size: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: ${(props) => props.backgroundColor};
    border: ${(props) => props.borderColor && `2px solid ${props.borderColor}`};
    opacity: ${(props) => props.opacity};
    transform: ${(props) => (props.backward ? 'rotate(180deg)' : '')};
    cursor: ${(props) => props.cursor};
    ${breakpoints.onlyMobile} {
        width: 18px;
        height: 18px;
    }
`;

const CircleIcon: React.FC<CircleIconProps> = ({
    type,
    active = true,
    backgroundColor = 'transparent',
    borderColor = 'transparent',
    onClick,
    className,
}) => (
    <CircleIconWrapper
        onClick={onClick}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        image={getImageFromType(type)}
        cursor={active ? 'pointer' : ''}
        opacity={active ? 1 : 0.2}
        backward={type === 'next'}
        style={{
            transform: (type === 'next' && 'rotate(180deg)') || '',
        }}
        className={className}
    />
);

export default CircleIcon;
