
import PopinPageLayout from './PopinPageLayout';
import { useTranslation } from 'react-i18next';

interface JobHelpPagePopinProps {
    onClose: () => void;
    permanentPage?: boolean;
}

const JobHelpPagePopin: React.FC<JobHelpPagePopinProps> = ({
    onClose,
    permanentPage = false,
}) => {
    const { t } = useTranslation(['JobHelpPopin']);
    const translationKeys: any = t('job_titles');
    console.log(translationKeys);

    return (
        <PopinPageLayout
            onClose={onClose}
            title={t('help')}
            subtitle={t('Job Descriptions')}
            permanentPage={permanentPage}
        >
            <div>
                <table
                    style={{
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '10px',
                        fontSize: '0.35em',
                        border: '1px solid gray',
                        borderRadius: '5px',
                        borderCollapse: 'collapse',
                        marginTop: '50px',
                    }}
                >
                    <tbody>
                        {translationKeys.map((key: any, index: number) => (
                            <tr key={key}>
                                <td
                                    style={{
                                        border: '1px solid gray',
                                        padding: '5px',
                                    }}
                                >
                                    <strong style={{ fontSize: '2em' }}>
                                        {key}
                                    </strong>
                                </td>
                                <td
                                    style={{
                                        border: '1px solid gray',
                                        padding: '5px',
                                        fontSize: '1.6em',
                                    }}
                                >
                                    {t(key)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </PopinPageLayout>
    );
};
export default JobHelpPagePopin;